import * as v from "valibot";
export default {
  ...v,
  reactHookFormResolver<TSchema extends v.BaseSchema<any, any>>(
    schema: TSchema,
    input: unknown,
    config?: v.SchemaConfig
  ): any {
    const result: any = {};
    const issues = v.safeParse(schema, input, config)?.issues;
    if (issues) {
      for (const e of issues) {
        const key = e?.path?.[0]?.key as string;
        if (!key) {
          throw new Error("unknown error key at " + e);
        }
        result[key] = e.message;
      }
    }
    return { values: input, errors: result };
  },
};
